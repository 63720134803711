import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { getPageLink } from '../constants';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import './ConfirmationPage.scss';
import { PageContentType } from '../context/PageContentType';

/**
 * Confirmation Page
 *
 * @param props
 * @constructor
 */
const ConfirmationPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const state = useContext(GlobalStateContext);

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.confirmation'),
      path: getPageLink(props.pageContext.lang, 'CONFIRMATION_PAGE'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'CONFIRMATION_PAGE')}
      pageTitle={t('confirmationPage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <h1>{t('confirmationPage.title')}</h1>

          <p>{t('confirmationPage.desc01')}</p>
          <p>{t('confirmationPage.desc02')}</p>
          <p>{t('confirmationPage.desc03')}</p>

          <p>{t('confirmationPage.desc04')}</p>
          <p className={'mdmp__confirmation-number'}>
            {t('confirmationPage.confirmationNumber')} #
            {state.confirmationNumber}
          </p>

          <div className={'mdmp__order-buttons'}>
            <button
              className="ontario-button ontario-button--secondary"
              onClick={() =>
                navigate(
                  getPageLink(
                    props.pageContext.lang,
                    'PRODUCT',
                    'PRODUCT_DETAILS',
                  ) + '/aris',
                )
              }
            >
              {t('buttons.goToProductAPIPage')}
            </button>
          </div>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default ConfirmationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
